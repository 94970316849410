'use strict';

import clientModal from '../clients/modal-client';
import trackingStatusModal from '../tracking-statuses/modal-tracking-status';
import deleteRepairModal from './modal-delete';
import createDepartureModal from '../departures/modal-create';

// Private functions and attributes
const inputSearch = $('#repairs-table--search');
const selectFilterField = $('#repairs-table--field-filter');
const selectInsurer = $('#repairs-table--insurer-filter');
const selectTrackingStatus = $('#repairs-table--status-filter');
const selectProduct = $('#repairs-table--product-filter');
const columnSwitcher = ('.column_switcher');

const init = () => {
    const table = $('#repairs-table');

    if (!table || !table.length) {
        return;
    }

    $([selectTrackingStatus, selectInsurer]).select2();
    const datatable = table.KTDatatable({
        // datasource definition
        data: {
            type: 'remote',
            source: {
                read: {
                    method: 'GET',
                    url: route('admin.repairs.all').toString(),
                },
            },
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            saveState: true,
        },

        // layout definition
        layout: {
            customScrollbar: false,
            scroll: true,
            // height: 400,
            footer: false,
            header: true,
        },

        // column sorting
        sortable: true,

        pagination: true,

        search: {
            onEnter: true,
            input: inputSearch,
        },

        // columns definition
        columns: [
            {
                field: '_',
                title: ' ',
                sortable: false,
                autoHide: false,
                textAlign: 'left',
                width: 70,
                template: (row, index, datatable) => {
                    const dropup = (datatable.getPageSize() - index) <= 4 ? 'dropup' : '';
                    const options = [];
                    let html = '';

                    if (permissions.includes('UPDATE_REPAIRS')) {
                        options.push({
                            id: row.id,
                            class: 'repair_edit_btn',
                            icon: 'la la-edit',
                            text: 'Editar'
                        });
                    }

                    if (row.delivered_at && !row.insurer_id && permissions.includes('GENERATE_DEPARTURE_ORDERS')) {
                        options.push({
                            id: row.id,
                            class: 'repair_departure_order_btn',
                            icon: 'la la-file-text',
                            text: 'O. de salida'
                        });
                    }

                    if (row.delivered_at && row.insurer_id && permissions.includes('GENERATE_REPAIRS_GUARANTEE')) {
                        options.push({
                            id: row.id,
                            class: 'repair_guarantee_btn',
                            icon: 'la la-file-text',
                            text: 'Cert. de garantía'
                        });
                    }

                    if (!row.delivered_at &&
                        (permissions.includes('GENERATE_DEPARTURE_ORDERS') ||
                        permissions.includes('GENERATE_REPAIRS_GUARANTEE'))
                    ) {
                        options.push({
                            id: row.id,
                            class: 'repair_create_departure_order_btn',
                            icon: 'la la-sign-out',
                            text: 'Dar salida'
                        });
                    }

                    if (permissions.includes('GENERATE_REPAIR_ORDERS')) {
                        options.push({
                            id: row.id,
                            class: 'repair_order_btn',
                            icon: 'la la-file-text',
                            text: 'O. de reparación'
                        });
                    }

                    if (permissions.includes('VIEW_REPAIR_STATUSES_HISTORY')) {
                        options.push({
                            id: row.id,
                            class: 'repair_statuses_history_btn',
                            icon: 'la la-list',
                            text: 'Historial de estados'
                        });
                    }

                    if (permissions.includes('VIEW_REPAIR_USER_DATA')) {
                        options.push({
                            id: row.client ? row.client.id : null,
                            class: 'repair_client_btn',
                            icon: 'la la-user',
                            text: 'Ver cliente'
                        });
                    }

                    if (row.report && permissions.includes('VIEW_REPAIR_REPORT_GENERAL')) {
                        options.push({
                            divider: true,
                        });
                    }

                    if (row.report && permissions.includes('VIEW_REPAIR_REPORT')) {
                        options.push({
                            id: row.id,
                            class: 'repair_report_btn',
                            icon: 'la la-eye',
                            text: 'Ver presupuesto'
                        });
                    }

                    if (permissions.includes('DELETE_REPAIRS')) {
                        options.push({
                            divider: true,
                        });

                        options.push({
                            id: row.id,
                            class: 'repair_delete_btn',
                            icon: 'la la-trash',
                            text: 'Eliminar'
                        });
                    }

                    if (!options.length) {
                        return row.id;
                    }

                    html = `
                        <div class="dropdown dropdown-inline ${dropup}">
                            <a href="#" class="btn btn-text-dark btn-hover-light font-weight-bold" data-toggle="dropdown">
                                <i class="flaticon-more-v2"></i>
                            </a>

                            <div class="dropdown-menu dropdown-menu-right actions">
                                ${
                                    options.map((option) => {
                                        if (option.divider) {
                                            return '<div class="dropdown-divider"></div>';
                                        }

                                        return `
                                            <button data-id="${option.id}" class="dropdown-item ${option.class}">
                                                <i class="${option.icon} mr-2"></i> ${option.text}
                                                </button>
                                        `;
                                    }).join('')
                                }
                            </div>
                        </div>
                    `;

                    return html;
                }
            }, {
                field: 'id',
                title: '#',
                sortable: true,
                autoHide: false,
                filterable: false,
                width: 50,
            }, {
                field: 'client_id',
                title: 'Cliente',
                sortable: true,
                autoHide: false,
                filterable: false,
                template: (row) => {
                    if (row.client.first_name && row.client.last_name) {
                        return `
                            <span
                                data-toggle="tooltip"
                                title="Nº de cliente: ${row.client.id}">
                                ${row.client.last_name} ${row.client.first_name} 
                            <span/>
                        `;
                    }

                    return `
                        <span
                            data-toggle="tooltip"
                            title="Nº de cliente: ${row.client.id}">
                            ${row.client.last_name}
                        <span/>
                    `;
                }
            }, {
                field: 'brand_id',
                title: 'Marca',
                sortable: true,
                autoHide: false,
                filterable: false,
                template: (row) => {
                    if (row?.brand?.description?.length) {
                        return `
                            <span
                                title="Descripción" 
                                data-content="${row?.brand?.description}"
                                data-trigger="hover"
                                data-toggle="popover">
                                ${row?.brand?.name}
                            <span>
                        `;
                    }

                    return row?.brand?.name;
                }
            }, {
                field: 'product_id',
                title: 'Producto',
                sortable: true,
                autoHide: false,
                filterable: false,
                template: (row) => {
                    if (row?.product?.description?.length) {
                        return `
                            <span
                                title="Descripción" 
                                data-content="${row?.product?.description}"
                                data-trigger="hover"
                                data-toggle="popover">
                                ${row?.product?.name}
                            <span>
                        `;
                    }

                    return row?.product?.name;
                }
            }, {
                field: 'last_tracking_status',
                title: 'Estado actual',
                sortable: false,
                autoHide: false,
                filterable: false,
                width: 200,
                template: (row) => {
                    var status = row.last_tracking_status;

                    if (status) {
                        const classes = [
                            'label',
                            'label-light-primary',
                            'label-inline',
                            'font-weight-bold'
                        ];

                        if (status?.description?.length) {
                            return `
                                <span
                                    title="Descripción" 
                                    data-content="${status.description}"
                                    data-trigger="hover"
                                    data-toggle="popover">
                                    <span
                                        class="${classes.join(' ').trim()}" 
                                        style="color: #fff; height:auto; background: ${status.color}">
                                        ${status.name}
                                    </span>
                                <span>
                            `;
                        }

                        return `
                            <span
                                class="${classes.join(' ').trim()}" 
                                style="color: #fff; height:auto; background: ${status.color}">
                                ${status.name}
                            </span>
                        `;
                    }
                }
            }, {
                field: 'insurer_id',
                title: 'Compañía',
                sortable: false,
                autoHide: false,
                filterable: false,
                template: (row) => {
                    if (row.insurer) {
                        return row.insurer.name;
                    }

                    if (row.third_party_insurer) {
                        return 'Compañía del cliente'
                    }

                    return null;
                }
            }, {
                field: 'sinister_number',
                title: 'Número de sntro',
                sortable: true,
                autoHide: false,
                filterable: false,
            }, {
                field: 'budget_at',
                title: 'Fecha de presupuesto',
                sortable: true,
                autoHide: false,
                filterable: false,
                type: 'datetime',
                template: (row) => {
                    return moment(row.budget_at).format('DD-MM-YYYY');
                }
            }, {
                field: 'created_at',
                title: 'Fecha de entrada',
                sortable: true,
                autoHide: false,
                filterable: false,
                type: 'datetime',
                template: (row) => {
                    return moment(row.created_at).format('DD-MM-YYYY');
                }
            }, {
                field: 'updated_at',
                title: 'Fecha de actualización',
                sortable: true,
                autoHide: false,
                filterable: false,
                type: 'datetime',
                template: (row) => {
                    return moment(row.updated_at).format('DD-MM-YYYY');
                }
            }, {
                field: 'delivered_at',
                title: 'Fecha de salida',
                sortable: true,
                autoHide: false,
                filterable: false,
                type: 'datetime',
                template: (row) => {
                    if (row.delivered_at) {
                        return moment(row.delivered_at).format('DD-MM-YYYY');
                    }

                    return '';
                }
            }
        ],

        rows: {
            beforeTemplate: (row, data, index) => {
                if (data.delivered_at) {
                    $(row).css('background', '#E1F0FF');
                }
            }
        }
    });

    /**
     * Table filter events
     */
    $(selectProduct).on('change', (e) => {
        const newData = $(e.target).select2('data')[0];
        const oldData = datatable.stateGet('product-filter');
        
        if (newData?.id == oldData?.id) {
            return;
        }

        if (newData) {
            datatable.stateKeep('product-filter', {
                id: newData?.id,
                text: newData?.text
            });
        }
        
        datatable.search(e.currentTarget.value, 'product_id');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(selectTrackingStatus).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'tracking_status_id');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(selectInsurer).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'insurer_id');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(selectFilterField).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'filter_by');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(columnSwitcher).change((e) => {
        const field = e.currentTarget.name;
        const active = e.currentTarget.checked;

        if (!datatable.getColumnByField(field)) {
            return;
        }

        datatable.columns(field).visible(active);
        datatable.stateKeep('columns', datatable.options.columns);
    });

    /**
     * Datatable events
     */
    datatable.on('datatable-on-init', () => {
        const tableMeta = datatable.stateGet('meta');
        const productFilter = datatable.stateGet('product-filter');
        const tableColumns = datatable.stateGet('columns');
        const urlParams = new URLSearchParams(window.location.search);

        if (productFilter) {
            const newOption = new Option(productFilter.text, productFilter.id, false, false);
            $(selectProduct).append(newOption).trigger('change');
        }

        if (urlParams.has('q')) {
            // refresh table state only with query string value
            $(inputSearch).val(urlParams.get('q'));
            datatable.setDataSourceQuery({});
            datatable.search(urlParams.get('q'));
            datatable.setDataSourceParam('pagination', {
                page: 1,
                perpage: datatable.getDataSourceParam('pagination.perpage')
            });
        }

        if (tableMeta && tableMeta.query && tableMeta.query.tracking_status_id) {
            $(selectTrackingStatus)
                .val(tableMeta.query.tracking_status_id)
                .trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.insurer_id) {
            $(selectInsurer)
                .val(tableMeta.query.insurer_id)
                .trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.filter_by) {
            $(selectFilterField).val(tableMeta.query.filter_by);
        }

        if (tableMeta && tableMeta.query && tableMeta.query.general_search) {
            $(inputSearch).val(tableMeta.query.general_search);
        }

        if (!tableColumns) {
            return null;
        }

        // Update table column visibility switchers
        $.each(tableColumns, function(index, obj) {
            const isVisible = obj.visible === undefined || obj.visible === true;

            datatable.columns(obj.field).visible(isVisible);
            datatable.stateKeep('columns', datatable.options.columns);
            $(`input[name="${obj.field}"]`).prop("checked", isVisible);
        });
    });

    datatable.on("datatable-on-layout-updated", () => {
        //NOTE: As we are adding content dynamically, we need to initialize all popover on every update
        $('[data-toggle="popover"]').popover();
        $('[data-toggle="tooltip"]').tooltip();
    });

    /**
     * Table action events
     */
    $(document.body).on('click', 'button.repair_edit_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.repairs.edit', {id: target.data('id')}).toString();

        window.open(url, '_self');
    });

    $(document.body).on('click', 'button.repair_order_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.repair-orders.get', {id: target.data('id')}).toString();

        window.open(url, '_blank')
    });

    $(document.body).on('click', 'button.repair_guarantee_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.guarantees.get', {id: target.data('id')}).toString();

        window.open(url, '_blank')
    });

    $(document.body).on('click', 'button.repair_departure_order_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.departure-orders.get', {id: target.data('id')}).toString();

        window.open(url, '_blank')
    });

    $(document.body).on('click', 'button.repair_create_departure_order_btn', (e) => {
        e.preventDefault();

        const target = $(e.currentTarget);
        const options = {
            id: target.data('id'),
        };

        createDepartureModal.init(options)
            .then((response) => {
                KTApp.block(table, {
                    message: 'Cargando...'
                });

                datatable.reload();

                if (!response) {
                    return null;
                }

                swal.fire({
                    title: 'Listo!',
                    text: 'Quiere generar la orden de salida o la garantía ahora?',
                    icon: 'info',
                    showCancelButton: true,
                    cancelButtonText: 'no',
                    confirmButtonText: 'si',
                    footer: 'Tambíen puedes realizar esta acción en otro momento'
                }).then((result) => {
                    if (result.value) {
                        if (response.route) {
                            window.open(response.route, '_blank');
                        }
                    }

                    // NOTE: We need refresh the page to reset de default departure status and UI
                    location.reload();

                    KTApp.unblock(table);
                });
            })
            .catch((jqXHR) => {
                KTApp.block(table, {
                    message: 'Cargando...'
                });

                location.reload();

                KTApp.unblock(table);
            });
    });

    $(document.body).on('click', 'button.repair_statuses_history_btn', (e) => {
        e.preventDefault();

        const target = $(e.currentTarget);
        const options = {
            general_search: target.data('id'),
            filter_by: 'repair_id'
        };

        KTApp.block(table, {
            message: 'Cargando...'
        });

        trackingStatusModal.init(options)
            .then(() => {
                KTApp.unblock(table);
            })
    });

    $(document.body).on('click', 'button.repair_client_btn', (e) => {
        e.preventDefault();

        const target = $(e.currentTarget);
        const options = {
            query: {
                general_search: target.data('id'),
                filter_by: 'id'
            }
        };

        KTApp.block(table, {
            message: 'Cargando...'
        });

        clientModal.init(options)
            .then(() => {
                KTApp.unblock(table);
            })
    });
    

    $(document.body).on('click', 'button.repair_report_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.reports.get-by-repair', {repair_id: target.data('id')}).toString();

        window.open(url, '_blank')
    });

    $(document.body).on('click', 'button.repair_delete_btn', (e) => {
        e.preventDefault();

        const target = $(e.currentTarget);
        const options = {
            id: target.data('id'),
        };

        KTApp.block(table, {
            message: 'Cargando...'
        });

        deleteRepairModal.init(options)
            .then((response) => {
                if (response) {
                    datatable.reload();
                }

                KTApp.unblock(table);
            })
            .catch(() => {
                datatable.reload();

                KTApp.unblock(table);
            });
    });
};

const destroy = () => {
    // We MUST get table element this way (we can't create global variable)
    $('#repairs-table').KTDatatable('destroy');
};

export default {
    init,
    destroy
};

